import React from 'react';
import './App.css';

import { Spring } from 'react-spring/renderprops';
import axios from 'axios';
import Fade from 'react-reveal/Fade';
import { Link, Element, scroller } from 'react-scroll';

import computerImg from './assets/shadowMacbook.png';
import mugImg from './assets/croppedMug.png';
import notepadImg from './assets/shadowNotepad.png';

import branding from './assets/branding.png';
import community from './assets/community.png';
import photography from './assets/photography.png';
import web from './assets/web.png';

import aboutImg from './assets/about.jpg';
import contactImg from './assets/fotoVeci.jpeg';

import logoFooter from './assets/logoFooter.png';
import goalsBg from './assets/goalsBg.png';

import casaVictor from './assets/casaVictor.png';
import rollerTech from './assets/rollerTech.png';
import vero from './assets/vero.png';
import romi from './assets/romi.png';
import zhar from './assets/zhar.png';
import softline from './assets/softline.png';
import lulizollo from './assets/lulizollo.png';
import ponchos from './assets/ponchos.png';

export default class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      windowHeight: window.document.documentElement.clientHeight,
      windowWidth: window.document.documentElement.clientWidth,
      name: '',
      email: '',
      message: '',
      status: null,
      buttonText: 'Enviar'
    }
  }

  handleChangeName = (event) => {
    this.setState({name: event.target.value});
  }

  handleChangeEmail = (event) => {
    this.setState({email: event.target.value});
  }

  handleChangeMessage = (event) => {
    this.setState({message: event.target.value});
  }

  handleSubmit = () => {
    if (this.state.status === "ok" || this.state.status === "error") {
      this.setState({ status: null, buttonText: "Enviar", name: '', email: '', message: '' });
    } else {
      this.setState({ status: 'sending', buttonText: "Enviando..." });

      axios({
        method:'post',
        url: "https://us-central1-website-luli.cloudfunctions.net/sendSupportEmail",
        data: {
          name: this.state.name,
          email: this.state.email,
          message: this.state.message
        }
        })
          .then((response) => {
            this.setState({ status: 'ok', buttonText: "Enviado!" });
          })
          .catch((error) => {
            //console.log(error);
            this.setState({ status: 'error', buttonText: "Error" });
          });
    }
  }

  goToContact = () => {
    scroller.scrollTo('contact', {
      duration: 500,
      delay: 0,
      smooth: true,
    });
  }

  goToInstagram = (url) => {
    window.open(url, "_blank");
  }

  render() {
    return (
      <div className="App">
        <Spring
          from={{ opacity: 0 }}
          to={{ opacity: 1 }}
          config={{ tension: 280, friction: 60, delay: 2000 }}>
          { props => 
            <div style={props} className="navbar">
              <Link activeClass="active" to="goals" spy={true} smooth={true} offset={-90} duration={500}>
                <p className="navLink firstLink">METAS</p>
              </Link>
              <Link activeClass="active" to="services" spy={true} smooth={true} offset={100} duration={500}>
                <p className="navLink">SERVICIOS</p>
              </Link>
              <Link activeClass="active" to="clients" spy={true} smooth={true} offset={0} duration={500}>
                <p className="navLink">CLIENTES</p>
              </Link>
              <Link activeClass="active" to="about" spy={true} smooth={true} offset={150} duration={500}>
                <p className="navLink">SOBRE MÍ</p>
              </Link>
            </div>
          }
        </Spring>
        
        <div className="heroBg" />

        <Spring
          from={{ marginRight: 'calc(-50vw)' }}
          to={{ marginRight: 'calc(0vw)' }}
          config={{ tension: 280, friction: 60, delay: 2000 }}>
          { props => 
            <div style={props} className="imgContainer">
              <img src={computerImg} className="computerImg" alt="Macbook" /> 
            </div>
          }
        </Spring>
        
        <div className="heroContainer">
          <Spring
            from={{ opacity: 0 }}
            to={{ opacity: 1 }}
            config={{ tension: 280, friction: 60, delay: 2000 }}>
            { props => 
              <p style={props} className="firstHeading">BRANDING - COMMUNITY MANAGEMENT - FOTOGRAFÍA</p>
            }
          </Spring>
          
          <Spring
            from={{ opacity: 0 }}
            to={{ opacity: 1 }}
            config={{ tension: 280, friction: 60, delay: 1000 }}>
            { props => 
              <p style={props} className="heroName">Lucila Elsegood</p>
            }
          </Spring>
          <Spring
            from={{ opacity: 0 }}
            to={{ opacity: 1 }}
            config={{ tension: 280, friction: 60, delay: 1000 }}>
            { props => 
              <p style={props} className="mobileHeroName leftHero">Lucila</p>
            }
          </Spring>
          <Spring
            from={{ opacity: 0 }}
            to={{ opacity: 1 }}
            config={{ tension: 280, friction: 60, delay: 1000 }}>
            { props => 
              <p style={props} className="mobileHeroName rightHero">Elsegood</p>
            }
          </Spring>
          
          <Spring
            from={{ opacity: 0 }}
            to={{ opacity: 1 }}
            config={{ tension: 280, friction: 60, delay: 2000 }}>
            { props => 
              <p style={props} className="subheading">Te propongo ayudarte en la construcción y administración de tu marca personal, gestionando la identidad y la imagen pública.</p>
            }
          </Spring>

          <Spring
            from={{ opacity: 0 }}
            to={{ opacity: 1 }}
            config={{ tension: 280, friction: 60, delay: 2000 }}>
            { props => 
              <div style={props} className="callToAction" onClick={this.goToContact}>CONTÁCTAME AHORA</div>
            }
          </Spring>
          
          <Spring
            from={{ marginRight: 'calc(-50vw)' }}
            to={{ marginRight: 'calc(0vw)' }}
            config={{ tension: 280, friction: 60, delay: 2000 }}>
            { props => 
              <div style={props} className="mugImgContainer">
                <img src={mugImg} className="mobileMugImg" alt="Taza de cafe" />
              </div>
            }
          </Spring>

          <Spring
            from={{ marginRight: 'calc(-50vw)' }}
            to={{ marginRight: 'calc(0vw)' }}
            config={{ tension: 280, friction: 60, delay: 2000 }}>
            { props => 
              <img style={props} src={mugImg} className="mugImg" alt="Taza de cafe"/>
            }
          </Spring>

          <Spring
            from={{ marginRight: this.state.windowWidth > 767 ? 'calc(-100vw)' : 'calc(0vw)', marginLeft: this.state.windowWidth > 767 ? 'calc(0vw)' : 'calc(-100vw)' }}
            to={{ marginRight: 'calc(0vw)', marginLeft: 'calc(0vw)' }}
            config={{ tension: 280, friction: 60, delay: 2000 }}>
            { props => 
              <img style={props} src={notepadImg} className="notepadImg" alt="Cuaderno" />
            }
          </Spring>
        </div>

        <div className="goalsContainer">
          <img src={goalsBg} className="goalsBg" alt="Background for goals" /> 
          <div className="goalsFirst">
            <Fade>
              <Element name="goals">
                <div className="goalContainer">
                  <p className="goalText">Mejorar el reconocimiento de marca y reputación de su empresa.</p>
                </div>
              </Element>
            </Fade>
            <Fade>
              <div className="goalContainer mobileGoal">
                <p className="goalText mobileGoalText">Fidelizar sus clientes actuales.</p>
              </div>
            </Fade>
            <Fade>
              <div className="goalContainer">
                <p className="goalText">Minimizar sus costos.</p>
              </div>
            </Fade>
          </div>
          <div className="goalsSecond">
            <Fade>
              <div className="goalContainerAlt mobileGoal">
                <p className="goalTextAlt mobileGoalText">Captar clientes potenciales.</p>
              </div>
            </Fade>
            <Fade>
              <div className="goalContainerAlt">
                <p className="goalTextAlt">Aumentar sus ventas.</p>
              </div>
            </Fade>
          </div>
        </div>

        <Element name="services">
          <div className="servicesContainer">
            <Fade>
              <div className="serviceFrame">
                <img src={branding} className="serviceImg" alt="Branding" />
                <p className="serviceTitle">Branding</p>
                <p className="serviceText">Concepción, construcción y posicionamiento de tu marca en forma integral y alineada con los objetivos de tu emprendimiento o empresa.</p>
              </div>
            </Fade>
            <Fade>
              <div className="serviceFrame">
                <img src={community} className="serviceImg" alt="Community Management" />
                <p className="serviceTitle">Community Management</p>
                <p className="serviceText">Gestión, análisis y optimización de toda la presencia online de tu empresa y creación de estrategias de acuerdo a técnicas consolidadas de marketing, tanto digital como tradicional.</p>
              </div>
            </Fade>
            <Fade>
              <div className="serviceFrame">
                <img src={photography} className="serviceImg" alt="Fotografía" />
                <p className="serviceTitle">Fotografía</p>
                <p className="serviceText">Proyección y captura de imagenes. Fotografía de productos para uso comercial o fotografía exterior.</p>
              </div>
            </Fade>
            <Fade>
              <div className="serviceFrame">
                <img src={web} className="serviceImg" alt="Diseño Web" />
                <p className="serviceTitle">Diseño Web</p>
                <p className="serviceText">Diseño y desarrollo a medida de páginas web o aplicaciónes mobile (iOS/Android) para incrementar la visibilidad de tu marca y mejorar la comunicación con tus clientes.</p>
              </div>
            </Fade>
          </div>
        </Element>

        <Element name="clients">
          <div className="clientsOuter">
            <Fade>
              <h3 className="clientsTitle">
                Confían en mi
                <span className="clientsTitleSemiUnderline" />
              </h3>
            </Fade>
            <div className="clientsContainer">
              <div className="clientsMain">
                <Fade>
                  <div className="clientsRow">
                    <div className="clientSingle" onClick={() => this.goToInstagram("https://www.instagram.com/verodelacanaltienda/?hl=es-la")}>
                      <img src={vero} alt="Vero de la Canal Logo" className="clientSingleImg" />
                      <p className="clientSingleTitle">Vero de la Canal Tienda</p>
                      <p className="clientSingleHandle">(@verodelacanaltienda)</p>
                    </div>
                    <div className="clientSingle" onClick={() => this.goToInstagram("https://www.instagram.com/romiciccale.rs/?hl=es-la")}>
                      <img src={romi} alt="Romina Ciccale" className="clientSingleImg" />
                      <p className="clientSingleTitle">Romina Ciccale</p>
                      <p className="clientSingleHandle">(@romiciccale.rs)</p>
                    </div>
                    <div className="clientSingle" onClick={() => this.goToInstagram("https://www.instagram.com/casavictoraccesorios/?hl=es-la")}>
                      <img src={casaVictor} alt="Casa Victor Logo" className="clientSingleImg" />
                      <p className="clientSingleTitle">Casa Victor</p>
                      <p className="clientSingleHandle">(@casavictoraccesorios)</p>
                    </div>
                  </div>
                </Fade>
                <Fade>
                  <div className="clientsRow">
                    <div className="clientSingle" onClick={() => this.goToInstagram("https://www.instagram.com/zharglobalservice/?hl=es-la")}>
                      <img src={zhar} alt="Zhar Global Service Logo" className="clientSingleImg" />
                      <p className="clientSingleTitle">Zhar Global Service</p>
                      <p className="clientSingleHandle">(@zharglobalservice)</p>
                    </div>
                    <div className="clientSingle" onClick={() => this.goToInstagram("https://www.instagram.com/cortinasrollertech/?hl=es-la")}>
                      <img src={rollerTech} alt="Cortinas Roller Tech Logo" className="clientSingleImg" />
                      <p className="clientSingleTitle">Cortinas Roller Tech</p>
                      <p className="clientSingleHandle">(@cortinasrollertech)</p>
                    </div>
                    <div className="clientSingle" onClick={() => this.goToInstagram("https://www.instagram.com/softline_llavallol/?hl=es-la")}>
                      <img src={softline} alt="Softline Llavallol Logo" className="clientSingleImg" />
                      <p className="clientSingleTitle">Softline Llavallol</p>
                      <p className="clientSingleHandle">(@softline_llavallol)</p>
                    </div>
                  </div>
                </Fade>
                <Fade>
                  <div className="clientsRow" style={{ marginBottom: 0, marginTop: 0 }}>
                    <div className="clientSingle" onClick={() => this.goToInstagram("https://www.instagram.com/od.lulizollo/?hl=es-la")}>
                      <img src={lulizollo} alt="Luciana Paula Zollo Logo" className="clientSingleImg" />
                      <p className="clientSingleTitle">Luciana Paula Zollo</p>
                      <p className="clientSingleHandle">(@od.lulizollo)</p>
                    </div>
                    <div className="clientSingle" onClick={() => this.goToInstagram("https://www.instagram.com/ponchos_argentinos/?hl=es-la")}>
                      <img src={ponchos} alt="Ponchos Argentinos Logo" className="clientSingleImg" />
                      <p className="clientSingleTitle">Ponchos Argentinos</p>
                      <p className="clientSingleHandle">(@ponchos_argentinos)</p>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </Element>

        <Element name="about">
          <div className="aboutContainer">
            <img src={aboutImg} className="aboutImg" alt="Lucila sacando una foto del Coliseo" />
            <div className="aboutTextContainer">
              <Fade>
                <p className="aboutTitle">Sobre Mí</p>
              </Fade>
              <Fade>
                <p className="aboutText">
                  Experimento, diseño y construyo. Me apasiona la fotografía, el running y una copa de vino. Disfruto pasar tiempo al aire libre y con mis amigos.
                  <br/><br/>
                  Desde muy chica sentí atracción por el diseño y el arte, y siempre me interesó ayudar a las personas.
                  <br/><br/>
                  Por eso estoy aquí para motivarte, ayudarte y acompañarte en la puesta en marcha de tu negocio, creando una marca con personalidad a través de una estructura clara y accionable que te permitirá no solo crecer en ventas si no también en engagement con tus clientes.
                </p>
              </Fade>
            </div>
          </div>
        </Element>
        <div className="contactOuterContainer">
          <Element name="contact">
            <img src={contactImg} className="contactImg" alt="Lucila sonriendo con una cámara de fotos en la mano." />
          </Element>
          <div className="contactContainer">
            <Fade>
              <div className="formContainer">
                <p className="formAux">Ponte en contacto</p>
                <p className="formSubText">Me encantaría ayudarte a potenciar tu emprendimiento.</p>
                <label className="formInputLabel">Nombre</label>
                <input type="text" disabled={this.state.status === 'sending'} className="formInputSimple" placeholder="Nombre" value={this.state.name} onChange={this.handleChangeName} />
                <label className="formInputLabel">Email</label>
                <input type="text" disabled={this.state.status === 'sending'} className="formInputSimple" placeholder="Email" value={this.state.email} onChange={this.handleChangeEmail} />
                <label className="formInputLabel">Mensaje</label>
                <textarea className="formTextArea" disabled={this.state.status === 'sending'} placeholder="Mensaje" value={this.state.message} onChange={this.handleChangeMessage} />
                <Spring
                  from={{ backgroundColor: '#333333' }}
                  to={{ backgroundColor: 
                    this.state.status === "sending"
                      ? '#2196f3'
                      : this.state.status === "ok"
                        ? '#63d176'
                        : this.state.status === 'error'
                          ? '#fc3d39'
                          : '#333333'
                  }}>
                  { props => 
                    <button type="button" className="formButton" style={props} onClick={this.handleSubmit} disabled={!this.state.name || !this.state.email || !this.state.message}>{this.state.buttonText}</button>
                  }
                </Spring>
              </div>
            </Fade>
            <Fade>
              <div className="sendEmailContainer">
                <p className="sendEmailAux">O envíame un email a</p>
                <p className="sendEmailAddress">elsegoodlucila@gmail.com</p>
              </div>
            </Fade>
          </div>
          <div className="footer">
            <p className="footerText footerLeft">2019 © Lucila Elsegood</p>
            <div className="logoFooterContainer footerRight">
              <p className="footerText designedBy">Diseñado por</p>
              <a className="logoFooter" href="https://www.estudioanima.com" target="_blank" rel="noopener noreferrer"><img src={logoFooter} style={{height: "100%"}} alt="Logo Anima"/></a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
